import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import GetStarted from "../img/get_started_svg.svg";

export default Headline;

function Headline() {
  return (
    <section className="headline-container">
      <div className={styles.wrapper}>
        <h1 className={styles.newTitle}>
          Turn your <span className={styles.gradient}>passion</span> into a
          fortune.
        </h1>
        <p className={styles.paragraph}>
          Host a show or an event online where your fans and audience can
          support your craft. Get paid for tickets and access to your live
          streams.
        </p>
        <Link to="/signup/3" className={styles.link}>
          <img src={GetStarted} alt="get started button" />
        </Link>
        <br />
      </div>
    </section>
  );
}
