import styles from "./styles.module.css";
import CategoriesCard from "./components/CategoriesCard";
import { seed } from "./seed";

export default HomePageSection2;

function HomePageSection2() {
  return (
    <section className={styles.homepage_section2}>
      <div className={styles.wrapper}>
        <div className={styles.title_wrapper}>
          <p className={styles.title}>Our Content</p>
          <p className={styles.paragraph}>
            <b>Who can use our platform?</b> Our platform accommodates various
            creative forms of live video performances.
          </p>
          <div className={styles["categories-wrapper"]}>
            {seed.map(({ image, title }) => (
              <CategoriesCard image={image} title={title} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
