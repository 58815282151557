import styles from "./styles.module.css";
import CaseStudiesCard from "./components/CaseStudiesCard";
import { seed } from "./seed";

export default HomePageSection4;

function HomePageSection4() {
  return (
    <section className={styles.homepage_section2}>
      <div className={styles.wrapper}>
        <div className={styles.title_wrapper}>
          <p className={styles.title}>Trust them, trust us</p>
          <p className={styles.paragraph}>
            <b>What have fans and streamers said about us?</b> Every day, Teder
            helps hundres of creators and artists connect with their fanbase and
            what's not to like? You can take their words for it.
          </p>
        </div>
        <div className={styles.case_studies}>
          {seed.map(({ image, name, title, quotation_mark, testimony }) => {
            return (
              <CaseStudiesCard
                image={image}
                name={name}
                title={title}
                quotation_mark={quotation_mark}
                testimony={testimony}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
