import React from "react";
import "../Components/HomePage/homepage.css";
import LandingV2 from "../Components/HomePage/LandingV2/LandingV2.js";
import Shows from "../Components/HomePage/Shows";
import TopNavBarV2 from "../Components/Navigation/TopNavBarV2";
import HomePageSection2 from "../Components/HomePage/HomePageSection2";
import HomePageSection3 from "../Components/HomePage/HomePageSection3";
import HomePageSection4 from "../Components/HomePage/HomePageSection4";
import HomePageSection5 from "../Components/HomePage/HomePageSection5";

const HomePage = () => {
  let Scroll = require("react-scroll");
  let Element = Scroll.Element;

  return (
    <>
      <TopNavBarV2 />
      <div id="home-page">
        <div id="landing">
          <LandingV2 />
        </div>
        <Element name="shows">
          <div id="shows">
            <Shows />
          </div>
        </Element>
      </div>
      <HomePageSection2 />
      <HomePageSection3 />
      <HomePageSection4 />
      <HomePageSection5 />
    </>
  );
};

export default HomePage;
