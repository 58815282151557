import styles from "./styles.module.css";
import Button from "../../Button";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
export default HomePageSection3;

function HomePageSection3() {
  return (
    <section className={styles.homepage_section3}>
      <div className={styles.wrapper}>
        <div className={styles.title_wrapper}>
          <p className={styles.title}>
            Host your audience where it's{" "}
            <span className={styles.gradient}>
              faster, smarter, and liveliest.
            </span>
          </p>
          <Link to="/signup/3">
            <Button style={{ width: "180px", fontFamily: "onestmedium" }}>
              Create an Event
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
}
