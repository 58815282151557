import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { display_show_list } from "../../Common/data";
import { updatHomepageShowsAction } from "../../redux/actions";
import { Box, useTheme } from "@mui/system";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ShowGrid from "./ShowGrid";
import Button from "../Button";
import "./homepage.css";

const Shows = () => {
  const [loadShows, setLoadShows] = useState(false);

  const dispatch = useDispatch();
  var currentState = useSelector((state) => {
    return state.TederReducerState;
  });
  useState(() => {
    if (!loadShows) {
      display_show_list()
        .then((result) => {
          dispatch(updatHomepageShowsAction(result.Recent_Show_list));
        })
        .catch((error) => {
          console.log("Error caught in update api");
          console.log(error);
          return false;
        });

      setLoadShows(true);
    }
  }, []);
  const theme = useTheme();
  const styles = {
    wrapper: {
      width: "100w",
    },
    genericContainer: {
      display: "flex",
      flexDirection: "row",
      width: "35%",
      alignItems: "center",
    },
    title: {
      fontFamily: "onestmedium",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100vw",
      height: "75px",
      padding: "1em",
    },
    button: {
      fontFamily: "onestmedium",
      margin: "0 0 1.2em 5em",
    },
    link: {
      textDecoration: "none",
      color: "white",
      "&:hover": {
        color: theme.palette.tederColors.v2Purple,
      },
    },
  };

  return (
    <Box component="div" sx={styles.wrapper}>
      <Box component="div" sx={styles.titleContainer}>
        <div style={styles.genericContainer}>
          <Link to="/" className={"link"} style={styles.link}>
            <p style={styles.title}>Latest</p>
          </Link>
          <Button main style={styles.button}>
            Upcoming
          </Button>
        </div>
        <Link to="/" style={styles.link}>
          <p style={styles.title}>All Shows</p>
        </Link>
      </Box>
      <br />
      <ShowGrid showsList={currentState.homepageShows} />
    </Box>
  );
};

export default Shows;
