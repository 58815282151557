import sharon from "../img/sharon.png";
import bruno from "../img/bruno.png";
import claudia from "../img/claudia.png";
import quotation_mark from "../img/quotation_img.png";

export const seed = [
  {
    id: 0,
    image: sharon,
    name: "Sharon Steiner",
    title: "Audience",
    quotation_mark: quotation_mark,
    testimony:
      "This is the smoothest streaming platform I have ever used. I hope Gangnam keeps using it to host their concerts.",
  },
  {
    id: 1,
    image: bruno,
    name: "Bruno Ribiero",
    title: "Performer",
    quotation_mark: quotation_mark,
    testimony:
      "I call Teder my digital stage because I don't have to worry about endless system glitches that ruin myshows anymore.",
  },
  {
    id: 2,
    image: claudia,
    name: "Claudia Früling",
    title: "Agent",
    quotation_mark: quotation_mark,
    testimony:
      "Selling tickets has never been easier. With Teder, my sales are boosted incredibly because it's very fast to use.",
  },
];
