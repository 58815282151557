const TEDER_CONFIG = () => {
    return {
      Endpoint: "https://api-test.tederlive.com",
      Resources: [
        "shows",
        "login",
        "artist",
        "admin",
        "schedule_event",
        "get_booking_agent_assigned_artists",
        "admin_get_customers",
      ],
      ShowsPhotoUrl: `https://photos-test.tederlive.com/shows-photo/`,
      ArtistPhotoUrl: `https://photos-test.tederlive.com/artist-photo/`,
      BaseUrl: `https://beta.tederlive.com`,
    };
  };
  // return {
  //   Endpoint: "https://api-prod.tederlive.com",
  //   Resources: [
  //     "shows",
  //     "login",
  //     "artist",
  //     "admin",
  //     "schedule_event",
  //     "get_booking_agent_assigned_artists",
  //     "admin_get_customers",
  //   ],
  //   ShowsPhotoUrl: `https://photos.tederlive.com/shows-photo/`,
  //   ArtistPhotoUrl: `https://photos.tederlive.com/artist-photo/`,
  //   BaseUrl: `https://tederlive.com`,
  // };
// };
export default TEDER_CONFIG;
