export default customHubspotAPI;

async function customHubspotAPI(properties = []) {
  const API = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTAL_ID}/${process.env.REACT_APP_GUI_ID}`;
  const timeStamp = Date.now();
  /* Hubspot expects custom properties to be an array of objects shaped like:
   [{ name: String , value: String }] */
  const formattedProperties = properties.map((propertyName) => ({
    ...propertyName,
    objectTypeId: "0-1",
  }));
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      submittedAt: timeStamp,
      fields: formattedProperties,
    }),
  };
  const response = await fetch(API, options);
  const data = await response.json();

  if (!data) return "No data found";
  return data;
}
