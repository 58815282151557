import styles from "./styles.module.css";

export default CategoriesCard;

function CategoriesCard(props) {
  const { image, title } = props;
  return (
    <div className={styles.wrapper}>
      <img src={image} alt="categories" className={styles.picture} />
      <div className={styles.label}>
        <p className={styles.title}>{title}</p>
      </div>
    </div>
  );
}
