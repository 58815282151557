import { React, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactLogo from "../../Visuals/logo.svg";
import { Box, Typography } from "@mui/material";
import Button from "../Button";
import "./topnavbar.css";
import SideBar from "./SideBar";
import MenuIcon from "./img/menu.svg";
import { artist_logout } from "../../Common/data";
import { AuthRoles } from "../../Common/global";
import { useSelector, useDispatch } from "react-redux";
import { UserType } from "../../util/constants";
import { logoutUserAction } from "../../redux/actions";
import { animateScroll as scroll } from "react-scroll";

function TopNavBarV2() {
  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch();
  const [showSideBar, setShowSideBar] = useState(false);
  const toggleSideBar = () =>
    showSideBar ? setShowSideBar(false) : setShowSideBar(true);

  const getCorrectRoute = () => {
    if (currentState.userContext.isSignupCompleted === 0)
      return currentState.userContext.userType === UserType.Artist
        ? "/signup/1"
        : "/basignup/1";
    if (!currentState.userContext.userType) return "/login";
    if (currentState.userContext.userType === UserType.Artist) return "/artist";
    if (currentState.userContext.userType === UserType.BookingAgent)
      return "/agent-dashboard";
  };

  const history = useHistory();

  const handleLogout = () => {
    artist_logout(currentState.userContext.userID);
    const tederLogoutDetails = {
      userID: "",
      userFirstName: "",
      userLastName: "",
      userEmail: "",
      pendingShows: 0,
      userLoggedIn: false,
      userType: AuthRoles.ANONYMOUS,
    };
    dispatch({ type: "CLEAR_DATA" });
    dispatch(logoutUserAction(tederLogoutDetails));
  };

  const isLoggedInOrOut = () => {
    if (currentState.userContext.userLoggedIn) return handleLogout();
    history.push("/login");
  };

  const signUpOrDashboard = () => {
    if (!currentState.userContext.userLoggedIn)
      return history.push("/signup/3");
    return history.push(
      currentState.userContext.userType === UserType.Artist
        ? "/artist"
        : currentState.userContext.userType === UserType.BookingAgent
        ? "/agent-dashboard"
        : null
    );
  };

  return (
    <>
      {showSideBar && (
        <SideBar
          toggleSideBar={toggleSideBar}
          setShowSideBar={setShowSideBar}
          sideBarStyle={showSideBar ? "sidebar" : "sidebar hide"}
          getCorrectRoute={getCorrectRoute}
        />
      )}
      <header className="header-container">
        <div className="logo-menu">
          <Link to="/">
            <img
              src={ReactLogo}
              className="logo"
              width="85"
              height="85"
              alt="Teder"
              loading="lazy"
            />
          </Link>
          <span className="menu-icon" onClick={() => toggleSideBar()}>
            <img
              src={MenuIcon}
              className="menu"
              width="15"
              height="15"
              alt=""
              loading="lazy"
            />
          </span>
        </div>
        <Box component="nav" className="links">
          <Box
            component="div"
            className="links-containers"
            onClick={() => scroll.scrollTo(2900)}
          >
            <Typography variant="p" className="nav-text">
              For Creators
            </Typography>
          </Box>
          <Box
            component="div"
            className="links-containers"
            onClick={() => scroll.scrollTo(4900)}
          >
            <Typography variant="p" className="nav-text">
              For Agents
            </Typography>
          </Box>
          <Box
            component="div"
            className="links-containers"
            onClick={() => scroll.scrollTo(1700)}
          >
            <Typography variant="p" className="nav-text">
              Watch Shows
            </Typography>
          </Box>
        </Box>
        <Box component="div" className="nav-buttons">
          {currentState.userContext.userRole === AuthRoles.ADMIN && (
            <Button onClick={() => history.push("/admin")}>Admin</Button>
          )}
          <Button onClick={isLoggedInOrOut}>
            {currentState.userContext.userLoggedIn ? "Logout" : "Login"}
          </Button>
          <Button main onClick={() => signUpOrDashboard()}>
            {currentState.userContext.userLoggedIn ? "Dashboard" : "Sign Up"}
          </Button>
        </Box>
      </header>
    </>
  );
}

export default TopNavBarV2;
