import React, { useState, useEffect, useRef } from "react";
import "../Styling/signup-confirmation.css";
import { Box } from "@mui/material";
import { confirm_sign_up, resend_confirmation_code } from "../Common/data";
import { useDispatch } from "react-redux";
import { push } from "react-router-redux";
import { useSelector } from "react-redux";

const SignUpConfirmation = () => {
  const [codeValues, setCodeValues] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
  });
  const [counter, setCounter] = useState(1);
  const [key, setKey] = useState("");

  const keyHandler = (event) => {
    // changing the state to the name of the key
    // which is pressed
    setKey(event.key);
  };
  const inputRefs = useRef([]);

  let formattedCode = Object.values(codeValues).join("");

  let codeError = false;
  const dispatch = useDispatch();
  const currentState = useSelector((state) => state.TederReducerState);

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleFocus = (index) => {
    console.log(
      "counter",
      counter,
      "active element:",
      document.activeElement.name,
      "is equal:",
      counter === parseInt(document.activeElement.name)
    );
  };

  const handleSubmit = (e) => {
    const confirm_sign_up_data = {
      username: currentState.userSignUp["username"],
      code: formattedCode,
    };

    confirm_sign_up(confirm_sign_up_data)
      .then((data) => {
        if (data.code === "InvalidVerificationCode") {
          codeError = true;
        } else {
          codeError = false;
          dispatch(push("/signup/confirmation-complete"));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleResendCode = (e) => {
    const resend_code_data = {
      username: currentState.userSignUp["username"],
    };

    resend_confirmation_code(resend_code_data)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  };

  const handlePaste = (event) => {
    const paste = event.clipboardData.getData("text").split("");

    setCodeValues({
      0: paste[0],
      1: paste[1],
      2: paste[2],
      3: paste[3],
      4: paste[4],
      5: paste[5],
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    //for now, this determines index of input ref
    if (parseInt(document.activeElement.name) === counter - 2) {
      setCounter((prevCounter) => prevCounter - 1);
    }
    setCounter((prevCounter) => prevCounter + 1);

    setCodeValues({
      ...codeValues,
      [name]: value,
    });

    inputRefs.current[counter].focus();

    //resets focused input
    if (counter >= inputRefs.current.length - 1) {
      setCounter(5);
    }
  };

  //Iterable values to map & render from stateful codeValues object
  const formattedValues = Object.entries(codeValues);

  const codeInputs = formattedValues.map((input, i) => {
    const [code] = input[1];
    return (
      <Box component="div" sx={{ margin: "2%" }}>
        <input
          type="text"
          key={i}
          name={i}
          value={code}
          onPaste={handlePaste}
          maxLength="1"
          ref={(element) => (inputRefs.current[i] = element)}
          onChange={handleChange}
          onFocus={() => handleFocus(i)}
        />
      </Box>
    );
  });

  return (
    <div className="container d-flex justify-content-center">
      <div className="confirmation-container">
        <div className="confirmation-header">
          <p>ENTER CONFIRMATION CODE</p>
        </div>
        <div className="confirmation-form">
          <div className="code-form-container">
            <p className="code-form-description">
              We just sent you a temporary code to validate your email address.
            </p>
            <p className="code-form-description">
              Please check your inbox and enter the 6 digit code below.
            </p>
            <form className="code-form">{codeInputs}</form>

            {/* 
            todo: grab error icons from material ui
            <p
              className="confirmation-error-text"
              style={
                codeError ? { visibility: "hidden" } : { visibility: "visible" }
              }
            >
              <span>
                <img src={errorIcon}></img>
              </span>
              Error: The code you entered is incorrect. Please try again.
            </p> */}

            <button
              onClick={handleSubmit}
              type="submit"
              disabled={codeValues["5"] ? false : true}
              style={
                codeValues["5"]
                  ? { backgroundColor: "rgba(237, 27, 80, 1" }
                  : { backgroundColor: "rgba(237, 27, 80, 0.5)" }
              }
            >
              <p
                className="button-text-1"
                style={
                  codeValues["5"]
                    ? { color: "white" }
                    : { color: "rgba(255, 255, 255, 0.5" }
                }
              >
                SUBMIT
              </p>
            </button>
            <p className="resend-code">
              Didn't get a code?{" "}
              <span
                onClick={handleResendCode}
                className="resend-code-clickable"
              >
                {" "}
                Click here to resend
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpConfirmation;
