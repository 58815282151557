import Config from "./config";
import API from "./api";

export const api = new API(Config().Endpoint, Config().Resources);

export const login_user_fb = (FBaccessToken) => {
  // console.log("inside Data - login_user_fb")
  // console.log(FBaccessToken)
  var body_param = {
    auth_token: FBaccessToken,
    auth_type: 1,
  };
  console.log("Inside Data----");
  return api.get_resource("login", "POST", body_param, "");
};

export const artist_update_1 = (ArtistUpdateJSON) => {
  api.setDefaultHeaders1();
  return api.get_resource("artist_update_1", "PUT", ArtistUpdateJSON, "");
};

export const booking_agent_update_1 = (BAUpdateJSON) => {
  api.setDefaultHeaders1();
  return api.get_resource("booking_agent_update_1", "PATCH", BAUpdateJSON, "");
};

export const get_booking_agent_income = () => {
  api.setDefaultHeaders1();
  return api.get_resource("get_booking_agent_income", "GET", "", "");
};

export const get_booking_agent_goals = (bookingAgentGoals) => {
  api.setDefaultHeaders1();
  return api.get_resource(
    "get_booking_agent_goals",
    "GET",
    bookingAgentGoals,
    ""
  );
};

export const get_booking_agent_list = () => {
  api.setDefaultHeaders1();
  return api.get_resource("get_booking_agent_list", "GET", "", "");
};

export const artist_signUp = (ArtistSignupJson) => {
  api.setDefaultHeaders1();
  return api.get_resource("artist_signUp", "POST", ArtistSignupJson, "");
};

export const sign_up_1 = (SignupJson) => {
  return api.get_resource("signup1", "POST", SignupJson, "");
};

export const confirm_sign_up = (ConfirmSignupJson) => {
  return api.get_resource("confirm_signup", "POST", ConfirmSignupJson, "");
};

export const resend_confirmation_code = (ResendConfirmationCodeJson) => {
  return api.get_resource(
    "resend_verification_code",
    "POST",
    ResendConfirmationCodeJson,
    ""
  );
};

export const login_1 = (LoginJSON) => {
  return api.get_resource("login1", "POST", LoginJSON, "");
};

export const ba_schedule_events = (BaScheduleEventsJSON) => {
  api.setDefaultHeaders1();
  return api.get_resource("schedule_event", "POST", BaScheduleEventsJSON, "");
};

export const get_ba_schedule_user_list = () => {
  api.setDefaultHeaders1();
  return api.get_resource("get_booking_agent_assigned_artists", "GET", "", "");
};

export const get_booking_agent_shows = () => {
  api.setDefaultHeaders1();
  return api.get_resource("get_booking_agent_shows", "GET", "", "");
};

export const ba_schedule_user_list = api.get_resource(
  "get_booking_agent_assigned_artists",
  "GET",
  "",
  ""
);

export const get_genders_list = () => {
  api.setDefaultHeaders1();
  return api.get_resource("get_genders_list", "GET", "", "");
};

export const display_my_shows = (teder_token) => {
  // console.log('##### REACHED DATA.JS ######')
  api.setDefaultHeaders1();
  return api.get_resource("get_my_shows", "POST", teder_token, "");
};

export const get_my_billing = (teder_token) => {
  api.setDefaultHeaders1();
  return api.get_resource("get_my_billing_info", "POST", teder_token, "");
};

export const get_stream_info = (params) => {
  api.setDefaultHeaders1();
  return api.get_resource("get_live_api_stream", "POST", params, null);
};

export const start_streaming = (params) => {
  api.setDefaultHeaders1();
  return api.get_resource("start_streaming", "POST", params, null);
};

export const end_streaming = (params) => {
  api.setDefaultHeaders1();
  return api.get_resource("/end_live_stream", "PATCH", params, null);
};

export const submit_contact_message = (contact_info) => {
  return api.get_resource(
    "submit_contact_message",
    "POST",
    contact_info,
    null,
    null
  );
};

// export const artist_logout = (teder_token) => {
//   return api.get_resource('logout','PATCH',teder_token,'')
// }

export const artist_logout = (data) => {
  api.setDefaultHeaders1();

  return api.get_resource("logout", "POST", data, "");
};

export const create_show = (CreateShowJson) => {
  api.setDefaultHeaders1();
  return api.get_resource("schedule_event", "POST", CreateShowJson, "");
};

export const forgot_password = (ForgotPasswordJson) => {
  return api.get_resource("forgot_password", "POST", ForgotPasswordJson, "");
};

export const reset_password = (ResetPasswordJson) => {
  return api.get_resource(
    "confirm_forgot_password",
    "POST",
    ResetPasswordJson,
    ""
  );
};

export const get_artist_details = () => {
  api.setDefaultHeaders1();
  return api.get_resource("get_artist_details", "GET", "", "");
};

export const get_booking_agent_details = () => {
  api.setDefaultHeaders1();
  return api.get_resource("get_booking_agent_details", "GET", "", "");
};

export const get_booking_agent_stats = () => {
  api.setDefaultHeaders1();
  return api.get_resource("get_booking_agent_stats", "GET", "", "");
};

export const get_booking_agent_associated_shows = () => {
  api.setDefaultHeaders1();
  return api.get_resource("get_booking_agent_associated_shows", "GET", "", "");
};

export const refresh_access_token = (params) => {
  api.setDefaultHeaders1();
  return api.get_resource("refresh_access_token", "POST", params, "");
};

export const show_genre_list = () => {
  api.setDefaultHeaders1();
  return api.get_resource("get_genre_list", "GET", "", "");
};

export const show_state_list = api.get_resource(
  "get_state_list",
  "GET",
  "",
  ""
);

export const show_country_list = api.get_resource(
  "get_country_list",
  "GET",
  "",
  ""
);

export const display_show_list = () => {
  api.setDefaultHeaders1();
  return api.get_resource("get_recent_show_list", "GET", "", "");
};
//export const display_show_list_by_artistID = api.get_resource('get_event_list_on_artist_id','GET','','')

export const display_show_details = (ShowID) => {
  return api.get_resource("get_event_details", "GET", "", "", ShowID);
};

export const update_artist_details = (UpdateArtistDetailsJson) => {
  api.setDefaultHeaders1();
  return api.get_resource(
    "update_artist_details",
    "PATCH",
    UpdateArtistDetailsJson,
    ""
  );
};

export const update_show = (UpdateShowJson) => {
  api.setDefaultHeaders1();
  return api.get_resource("update_show", "PATCH", UpdateShowJson, "");
};

export const cancel_show = (showID) => {
  api.setDefaultHeaders1();
  return api.get_resource("cancel_show", "PATCH", showID, "");
};

// export const display_show_details = (event_id) => {
//   console.log("inside Data - display_show_details")
//   console.log(event_id)

//   api.get_resource('get_event_details/'+event_id,'GET','','').then((data) => {
//     console.log("display_show_details has returned")
//     console.log(data)
//     return data
//   })
//   .catch((error) => {
//     console.log("Error in display_show_details")
//     console.log(error)
//   })
// }

export const purchasing_ticket = (PurchaseTicketJson) => {
  api.setDefaultHeaders1();
  return api.get_resource("purchase_ticket", "POST", PurchaseTicketJson, "");
};
export const admin_get_artist_ticket_info = (artist_id) => {
  api.setDefaultHeaders1();
  return api.get_resource(
    "admin_get_artist_ticket_info",
    "POST",
    artist_id,
    "",
    ""
  );
};

export const admin_get_shows = (teder_token) => {
  api.setDefaultHeaders1();
  return api.get_resource("admin_get_shows", "POST", teder_token, "");
};

export const admin_get_tickets_info = (data) => {
  api.setDefaultHeaders1();
  return api.get_resource("admin_get_tickets_info", "POST", data, "");
};

export const admin_get_tickets_per_show = (show_id) => {
  api.setDefaultHeaders1();
  return api.get_resource("admin_get_tickets_per_show", "POST", show_id, "");
};

export const admin_get_artist_bank = (adminJson) => {
  api.setDefaultHeaders1();
  return api.get_resource("admin_get_artist_bank", "POST", adminJson, "");
};

export const admin_get_users = (teder_token) => {
  api.setDefaultHeaders1();
  return api.get_resource("admin_get_users", "POST", teder_token, "");
};

export const admin_run_vimeo_prep = (teder_token) => {
  api.setDefaultHeaders1();
  return api.get_resource("admin_run_vimeo_prep", "POST", teder_token, "");
};

export const admin_update_video_ready_to_stream = (body) => {
  api.setDefaultHeaders1();
  return api.get_resource(
    "admin_update_video_ready_to_stream",
    "POST",
    body,
    ""
  );
};

export const admin_get_video_status = (body) => {
  api.setDefaultHeaders1();
  return api.get_resource("admin_get_video_status", "POST", body, "");
};

export const admin_end_video = (body) => {
  api.setDefaultHeaders1();
  return api.get_resource("admin_end_video", "POST", body, "");
};

export const admin_get_video_stream_details = (body) => {
  api.setDefaultHeaders1();
  return api.get_resource("admin_get_video_stream_details", "POST", body, "");
};

export const admin_reset_show_status = (showId) => {
  api.setDefaultHeaders1();
  return api.get_resource("admin_reset_show_status", "POST", showId, "");
};

export const admin_get_customers = (user_type) => {
  api.setDefaultHeaders1();
  return api.get_resource("admin_get_customers", "POST", user_type, "");
};

export const admin_get_temp_recording = (payload) => {
  api.setDefaultHeaders1();
  return api.get_resource("live_streams_temporary_records", "GET", null, payload, "");
}

export const admin_get_stream_stats = (payload) => {
  api.setDefaultHeaders1();
  return api.get_resource("live_streams_stats", "GET", null, payload, "");
}

export const admin_convert_to_vod = (payload) => {
  api.setDefaultHeaders1();
  return api.get_resource("live_streams_temp_to_vod", "GET", null, payload, "");
}

export const generate_live_stream_key = ({ event_id, event_name } ) => {
  api.setDefaultHeaders1();
  return api.get_resource("generate_live_api_stream", "POST", { event_id, name: event_name }, "");
}
// export const exampleData = api.get_resource('shows','GET','','').then((data) => {
//     console.log("hello i'm inside the then - this post data reteirval")
//     console.log(data)
//   })
//   .catch((error) => {
//     console.log(error)
//   })

// export const exampleData2 = (body_data) => {
//     api.get_resource('shows','GET',body_data,'').then((data) => {
//         console.log("hello i'm inside the then - this post data reteirval")
//         console.log(data)
//       })
//       .catch((error) => {
//         console.log(error)
//       })
// }

// export const exampleData3 = (url_params) => {
//     api.get_resource('shows','GET','',url_params).then((data) => {
//         console.log("hello i'm inside the then - this post data reteirval")
//         console.log(data)
//       })
//       .catch((error) => {
//         console.log(error)
//       })
// }
