import React, { useState, useEffect } from "react";
import { show_genre_list, artist_update_1 } from "../../../Common/data";
import Select from "react-select";
import "./css/StepTwo.css";
import { useSelector, useDispatch } from "react-redux";

import UploadPhoto from "../../../HelperFunctions/UploadPhoto";
import { setRegistrationStep3Action } from "../../../redux/actions";
import { push } from "connected-react-router";

function SignUpFormTwo() {
  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch();
  const [agreementStyle1, setAgreementStyle1] = useState("checkbox-1");
  const [agreementStyle2, setAgreementStyle2] = useState("checkbox-2");
  const [nextButtonStyle, setNextButtonStyle] = useState(
    "next-button-incomplete"
  );
  const [genreList, setGenreList] = useState([]);
  const [genreLoad, setGenreLoad] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState([]);
  const [stageName, setStageName] = useState("");
  const [textAreaText, setTextAreaText] = useState("");
  const [soundCloudText, setSoundCloudText] = useState("");
  const [tikTokText, setTikTokText] = useState("");
  const [igText, setIgText] = useState("");
  const [ytText, setYtText] = useState("");
  const [webText, setWebText] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const customTheme = (theme) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#ed1b50",
        primary: "black",
      },
    };
  };

  const customStyles = {
    menuList: (styles) => ({
      ...styles,
      color: "black",
      // // background: "#ed1b50",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "linear-gradient(180deg, #582EFF 0%, rgba(219, 53, 84, 0.82) 100%)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
      color: isFocused ? "white" : "black",
    }),
  };

  useEffect(() => {
    show_genre_list()
      .then((res) => {
        const response = JSON.parse(res.genreList);
        const formatResponse = response.map((option, index) => ({
          label: option.GenreName,
          value: option.GenreName,
          key: index + 1,
        }));
        setGenreList(formatResponse);
      })
      .catch((err) => console.error(err));
    setGenreLoad(true);
    if (
      agreementStyle1 === "checkbox-1-checked" &&
      agreementStyle2 === "checkbox-2-checked"
    ) {
      setNextButtonStyle("next-button-complete");
    } else {
      setNextButtonStyle("next-button-incomplete");
    }
  }, [agreementStyle1, agreementStyle2]);

  const getFormJson = () => {
    let temp = [];
    selectedGenre.map((el) => {
      return temp.push(el.key);
    });
    //console.log("temp d", temp);

    let ArtistSignupJson = {
      auth_token: currentState.userRegistrationStep1.userID,
      auth_type: 1,
      FirstName: currentState.userRegistrationStep1.firstName,
      LastName: currentState.userRegistrationStep1.lastName,
      PhotoPath: currentState.userRegistrationStep3.photoPath,
      Address: currentState.userRegistrationStep1.address,
      City: currentState.userRegistrationStep1.city,
      Email: currentState.userRegistrationStep1.email,
      State: currentState.userRegistrationStep1.state,
      Country: currentState.userRegistrationStep1.country,
      ZipCode: currentState.userRegistrationStep1.zipCode,
      PhoneNo: currentState.userRegistrationStep1.phoneNo,
      BankName: "",
      RoutingNo: "",
      AccNo: "",
      StageName: stageName,
      GenreList: temp,
      Bio: textAreaText,
      SoundcloudHandle: soundCloudText,
      InstagramHandle: igText,
      YouTubeHandle: ytText,
      TikTokHandle: tikTokText,
      Website: webText,
      ReferralCode: currentState.userRegistrationStep1.referralCode,
      UserType: currentState.userRegistrationStep1.userType,
    };
    return ArtistSignupJson;
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    let registrationDetails;
    registrationDetails = {
      ...currentState.userRegistrationStep3,
      [name]: value,
    };

    dispatch(setRegistrationStep3Action(registrationDetails));
  };

  const handleAgreementClick1 = () => {
    let registrationDetails;
    if (agreementStyle1 === "checkbox-1") {
      registrationDetails = {
        ...currentState.userRegistrationStep3,
        checkedTerms: true,
      };
      dispatch(setRegistrationStep3Action(registrationDetails));
      setAgreementStyle1("checkbox-1-checked");
    } else {
      registrationDetails = {
        ...currentState.userRegistrationStep3,
        checkedTerms: false,
      };
      dispatch(setRegistrationStep3Action(registrationDetails));

      setAgreementStyle1("checkbox-1");
    }
  };

  const handleAgreementClick2 = () => {
    let registrationDetails;
    if (agreementStyle2 === "checkbox-2") {
      registrationDetails = {
        ...currentState.userRegistrationStep3,
        checkedPrivacy: true,
      };
      dispatch(setRegistrationStep3Action(registrationDetails));

      setAgreementStyle2("checkbox-2-checked");
    } else {
      registrationDetails = {
        ...currentState.userRegistrationStep3,
        checkedPrivacy: false,
      };
      setAgreementStyle2("checkbox-2");
    }
    dispatch(setRegistrationStep3Action(registrationDetails));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    let registrationDetails = {
      ...currentState.userRegistrationStep3,
      SChandle: soundCloudText,
      IGhandle: igText,
      YThandle: ytText,
      TThandle: tikTokText,
      website: webText,
      stageName: stageName,
      bio: textAreaText,
      genres: selectedGenre,
    };
    dispatch(setRegistrationStep3Action(registrationDetails));

    setFormSubmitted(true);
    let formJson = getFormJson();
    artist_update_1(formJson)
      .then((res) => {
        dispatch(push("/artist"));
      })
      .catch((err) => console.error(err));
  };

  const signUpFormTwo = () => (
    <div className="sign-up-form-2-container">
      <div className="sign-up-form-2-content">
        <form className="sign-up-form-2-form" onSubmit={handleFormSubmit}>
          <div className="sign-up-title">
            <p>ARTIST PROFILE</p>
          </div>
          <div className="sign-up-form-2-info-container">
            <UploadPhoto
              handleChange={handleChange}
              message={"Upload Profile Photo"}
              photo={currentState.userRegistrationStep3.photoPath || null}
            />

            <div className="artist-form-info">
              <div className="stage-name-music-genre-input">
                <div className="stage-name-input">
                  <label>Stage Name</label>
                  <input
                    value={stageName}
                    onChange={(e) => setStageName(e.target.value)}
                    name="stageName"
                  ></input>
                </div>
                <div className="music-genre-input">
                  <label>Music Genre</label>

                  <Select
                    placeholder="Select a genre"
                    options={genreList}
                    isMulti
                    onChange={setSelectedGenre}
                    theme={customTheme}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="bio-input">
                <label>Bio</label>
                <div className="textarea-container">
                  <textarea
                    name="bio"
                    maxLength="300"
                    value={textAreaText}
                    onChange={(e) => {
                      setTextAreaText(e.target.value);
                    }}
                  ></textarea>
                  <span>
                    <div className="current-text-count">
                      <p>{textAreaText.length}</p>
                    </div>
                    <div className="out-of-300">
                      <p>/300</p>
                    </div>
                  </span>
                </div>
              </div>
              <div className="sound-cloud-tiktok-input">
                <div className="sound-cloud-input">
                  <label>Sound Cloud</label>
                  <input
                    onChange={(e) => setSoundCloudText(e.target.value)}
                    value={soundCloudText}
                    name="SChandle"
                  ></input>
                  <p className="input-placeholder">@</p>
                </div>
                <div className="tik-tok-input">
                  <label>Tiktok</label>
                  <input
                    onChange={(e) => setTikTokText(e.target.value)}
                    value={tikTokText}
                    name="TThandle"
                  ></input>
                  <p className="input-placeholder">@</p>
                </div>
              </div>
              <div className="instagram-youtube-input">
                <div className="instagram-input">
                  <label>Instagram</label>
                  <input
                    name="IGhandle"
                    onChange={(e) => setIgText(e.target.value)}
                    value={igText}
                  ></input>
                  <p className="input-placeholder">@</p>
                </div>
                <div className="youtube-input">
                  <label>Youtube</label>
                  <input
                    name="YThandle"
                    value={ytText}
                    onChange={(e) => setYtText(e.target.value)}
                  ></input>
                  <p className="input-placeholder">@</p>
                </div>
              </div>
              <div className="website-agreement-input">
                <div className="website-input">
                  <label>Website</label>
                  <input
                    name="website"
                    value={webText}
                    onChange={(e) => setWebText(e.target.value)}
                  ></input>
                </div>
                <div className="agreement-input">
                  <div
                    className="agreement-1"
                    name="checkedTerms"
                    onClick={handleAgreementClick1}
                  >
                    <div className={agreementStyle1}></div>
                    <p>I agree to Teder's Terms & Conditions</p>
                  </div>
                  <div
                    className="agreement-2"
                    onClick={handleAgreementClick2}
                    name="checkedPrivacy"
                  >
                    <div className={agreementStyle2}></div>
                    <p>I agree to Teder's Privacy Policy</p>
                  </div>
                </div>
              </div>
              <div className="next-button-container">
                <button
                  disabled={nextButtonStyle === "next-button-incomplete"}
                  className={nextButtonStyle}
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  return genreLoad ? signUpFormTwo() : null;
}

export default SignUpFormTwo;
