import styles from "./styles.module.css";
import Button from "../../Button";

export default HomePageSection5;

function HomePageSection5() {
  return (
    <section className={styles.homepage_section5}>
      <div className={styles.wrapper}>
        <div className={styles.title_wrapper}>
          <p className={styles.title}>For Agents</p>
          <p className={styles.paragraph}>
            <b>Are you an agent or an event organizer?</b> Organize and handle
            all your events in one space. Create unlimited access to events.
            Automate your sales. Keep 95% of your revenue. Maximize your
            earnings by making premium seamless live streaming experiences for
            your clients. Drive higher sales through analytical insights. Easily
            scale multiple client-base.
          </p>
          <Button
            secondary
            style={{ width: "180px", fontFamily: "onestmedium" }}
          >
            Get Started
          </Button>
        </div>
      </div>
    </section>
  );
}
