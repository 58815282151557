import React, { useState } from "react";
import Config from "../../Common/config";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Box, Typography, useMediaQuery } from "@mui/material";
import ArrowRight from "./img/arrow-right.svg";
import Price from "./img/price_sign.svg";
import Button from "../Button";
import { useStyles } from "./LandingV2/styles";
import { useSelector } from "react-redux";
import "./homepage.css";

const ShowCard = ({ showListing }) => {
  const baseShowsURL = Config().ShowsPhotoUrl;
  const ArtistPhotoPath = Config().ArtistPhotoUrl;
  const styles = useStyles();
  const [showLink, setShowLink] = useState(false);
  const matches = useMediaQuery("(max-width:600px)");
  const history = useHistory();
  const currentState = useSelector((state) => state.TederReducerState);

  const decideRoute = () =>
    currentState.userContext.userLoggedIn
      ? `/shows/${showListing.ID}`
      : `/shows/${showListing.ID}/fan`;

  return (
    <Box component="div" sx={styles.wrapper}>
      <Box
        component="img"
        src={`${baseShowsURL}${showListing.PhotoPath}`}
        alt="placeholder"
        loading="lazy"
        sx={styles.image}
      />
      <Box
        component="img"
        src={ArrowRight}
        sx={styles.arrow}
        alt="View show info"
        onClick={() => history.push(decideRoute())}
      />
      <Box className={styles.infoBox}>
        <Typography
          variant="h5"
          className={"gradient"}
          sx={{
            fontFamily: "onestmedium",
            textAlign: "left",
            margin: "5px 0 0 15px",
          }}
        >
          {showListing.EventName}
        </Typography>
        <br />
        <Box style={styles.infoText}>
          <Box sx={styles.priceText}>
            <Box
              component="img"
              src={Price}
              sx={styles.price}
              alt="cost per ticket"
            />
            <span>${showListing.TicketPrice} per ticket</span>
          </Box>
          <Box sx={styles.artistText}>
            <Box
              component="img"
              sx={styles.artistPhoto}
              src={`${ArtistPhotoPath}${showListing.ArtistPhotoPath}`}
              alt="artist photo"
            />
            <Box component="div" sx={styles.infoText}>
              {matches ? (
                <>
                  <Typography variant="h4" sx={styles.artistName}>
                    {showListing.StageName}
                  </Typography>
                  <Typography variant="p" sx={styles.title}>
                    Organizer
                  </Typography>{" "}
                </>
              ) : (
                <>
                  {" "}
                  <h4 className={"artistName"}>{showListing.StageName}</h4>
                  <p className={"title"}>Organizer</p>
                </>
              )}
            </Box>
          </Box>
          <Button
            main
            style={styles.button}
            onClick={() => history.push(decideRoute())}
          >
            Buy
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ShowCard;
