import React, { useState } from 'react';
// import {Link} from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { start_streaming, end_streaming, generate_live_stream_key } from '../../../Common/data';
import { updateShowAction } from '../../../redux/actions';
import Modal from 'react-modal';
import "./css/stream.css";
import step1 from "./img/obs/obs_step1.png";
import step2 from "./img/obs/obs_step2.png";
import step3 from "./img/obs/obs_step3.png";
import step4 from "./img/obs/obs_step4.png";
import step5 from "./img/obs/obs_step5.png";
import step6 from "./img/obs/obs_step6.png";
import step6_mini from "./img/obs/obs_step6_mini.png";
import step7 from "./img/obs/obs_step7.png";
import Config from "../../../Common/config";

const StreamShowPage = ({ history }) => {
    const dispatch = useDispatch(); //to update our redux state
    const currentState = useSelector((state) => state.TederReducerState);
    let currentShow = ''
    let currentShowName = ''
    if (currentState.viewShowDetails) {
        currentShow = currentState.viewShowDetails.showID;
        currentShowName = currentState.viewShowDetails.showName;
    } else {
        dispatch(push('/'))
    }

    // const [streamKey, setStreamKey] = useState('')
    const [readyToStreamError, setReadyToStreamError] = useState()
    // const [endStreamError, setEndStreamError] = useState();
    const [, setEndStreamError] = useState();

    const saveStreamKey = key => {
        let updateShowDetails = { ...currentState.viewShowDetails, showStreamKey: key };
        dispatch(updateShowAction(updateShowDetails))
    }

    const handleSetShowReady = () => {
        const params = {
            ShowID: currentShow,
            ShowName: currentShowName
        }
        start_streaming(params).then((result) => {
            if (result.code === "Success") {
                // setStreamKey(result.Vimeo_StreamKey)
                saveStreamKey(result.Vimeo_StreamKey)
            }
            else {
                setReadyToStreamError(result.message)
                console.log(result)
                generate_live_stream_key({ event_id: params.ShowID, event_name: params.ShowName }).then(res => {
                    console.log(res)
                    let { body } = res
                    if (body) {
                        body = JSON.parse(body)
                        if (body?.response) {
                            const { key } = body.response || {}
                            if (key) saveStreamKey(key)
                            // FIXME: response handled
                        }
                    }
                }).catch(error => console.error(error))

            }

        }).catch((error) => {
            console.log(error)
            setReadyToStreamError(error)
        })

    }

    const handleSetFinishShow = () => {
        const params = {
            ShowID: currentShow
        }
        end_streaming(params).then((result) => {
            if (result.code === "Success") {
                alert("Show Ended")
            }
            else {
                setEndStreamError(result.message)
                console.log(result)
                alert("Something went wrong")
            }

        }).catch((error) => {
            console.log(error)
            alert(error)
            setEndStreamError(error)
        })

    }

    const backButton = () => history.goBack()

    // const streamPage = () => (
    //     <div className="container content-padding">
    //     {console.log(currentState.viewShowDetails.showStreamKey)}
    //         <div className="text-center">
    //             <button className="green-teder-btn" onClick={backButton}>Back</button>
    //        </div>
    //        <br/><br/>
    //             <h2>Start streaming</h2>
    //             <br/>
    //         <div className="row">
    //             <div className="form-group col-lg">
    //             <h3>Step 1 - Activate your Stream</h3>
    //                  <div className="row teder-top-margin">
    //                      <button onClick={handleSetShowReady} className="green-teder-btn">get stream key</button>
    //                      {/* <button onClick={() => setKeyWarning(true)} className="green-teder-btn">get stream key</button> */}

    //                  </div>
    //                  <div className="row">
    //                     <small className="small-green-text">{readyToStreamError}</small>
    //                  </div>
    //                  <div className="row teder-top-margin">
    //                  Your Stream key is : 
    //                         {/* <input className="form-control artist-textbox-style" type="text" value={streamKey} readOnly></input> <br/> */}
    //                         <input className="form-control artist-textbox-style" type="text" value={currentState.viewShowDetails.showStreamKey} readOnly></input> <br/>
    //                  </div>
    //                  <div className="row teder-top-margin">
    //                  Your Stream Show Url : 
    //                         <input className="form-control artist-textbox-style" type="text" value={"https://onteder.com/#/shows/" + currentState.userShows[0].ID} readOnly></input> <br/>
    //                  </div>
    //                  <div className="row teder-top-margin">
    //                      <button onClick={handleSetFinishShow} className="green-teder-btn">Finish my Show</button>
    //                  </div>
    //                  <div className="row">
    //                     <small className="small-green-text">{endStreamError}</small>
    //                  </div>
    //                  <div className="row">
    //                     <small className="small-green-text">**Once you finish your show you will not be able to stream anymore.</small>
    //                  </div>
    //             </div>
    //             <div className="form-group col-lg">
    //                 <h3>Step 2 - Choose your Stream option</h3>
    //                 <div className="row teder-top-margin">
    //                     <p>
    //                         {/* Option 1: Stream with <Link to={"/shows/stream/browser/" + currentState.userShows[0].ID}>browser</Link><br/> */}
    //                         {/* Option 2: Stream with <Link to={"/shows/stream/obs/" + currentState.userShows[0].ID}>OBS</Link> */}
    //                         Stream with <Link to={"/shows/stream/obs/" + currentShow}>OBS</Link>
    //                     </p>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // )

    const obsInstructions = () => (
        <div>
            <h3>Streaming with OBS Studio</h3><br />
            <label className="teder-form-label">Step 1:</label>
            <p>
                Download <a href="https://obsproject.com/download" className="static-link" target="blank">OBS Studio</a> for your Mac, Windows, or Linux computer.
            </p>

            <label className="teder-form-label">Step 2:</label>
            <p>
                Install and launch OBS Studio.
            </p>

            <label className="teder-form-label">Step 3:</label>
            <p>
                Open <span className="mint-color">Settings</span> and select <span className="mint-color">Vimeo</span> as your service.
            </p>
            <img width="100%" src={step1} alt="step1" />
            <br /><br />
            <img width="100%" src={step2} alt="step2" />
            <br /><br />
            <label className="teder-form-label">Step 4:</label>
            <p>
                Paste your <span className="mint-color">Stream Key</span> and continue by pressing <span className="mint-color">OK</span>.
            </p>

            <label className="teder-form-label">Step 5:</label>
            <p>
                Set your <span className="mint-color">Audio Input Capture</span> device where your audio stream will be coming from.
            </p>
            <img width="100%" src={step3} alt="step3" />
            <br /><br />
            <img width="100%" src={step4} alt="step4" />
            <br /><br />
            <img width="100%" src={step5} alt="step5" />
            <br /><br />

            <label className="teder-form-label">Step 6:</label>
            <p>
                Set your <span className="mint-color">Video Capture Device</span> where your video stream will be coming from.
            </p>

            <img width="100%" src={step6} alt="step6" />
            <br /><br />
            <img width="50%" src={step6_mini} alt="step6_mini" />
            <br /><br />

            <label className="teder-form-label">Step 7:</label>
            <p>
                Press <span className="mint-color">Start Streaming</span> to begin your broadcast.
            </p>
            <img width="100%" src={step7} alt="step7" />
            <br /><br />

            <label className="teder-form-label">Step 8:</label>
            <p>
                You can visit <a href="/" className="static-link" target="blank">tederlive.com</a> to go to the <span className="mint-color">Set Up Stream</span> section of your show to end it whenever you’re done. Just press <span className="mint-color">Finish Show</span>.
            </p>
            <br /><br /><br />
        </div>
    )

    const [getStreamKey, setGetStreamKey] = useState(false);
    const [finishShow, setFinishShow] = useState(false);

    const handleStreamKey = () => {
        setGetStreamKey(true)
        handleSetShowReady()
    }

    const handleFinishShow = () => {
        setFinishShow(true)
        handleSetShowReady()
    }

    const finishMyShow = () => {
        setFinishShow(false)
        handleSetFinishShow()
    }

    const prepStream = () => (
        <>
            <h2>Set up to stream</h2>
            <div className="edit-profile-buttons-container">
                <button className="green-teder-btn" onClick={backButton}>Back</button>
                <button className="red-teder-btn" onClick={handleStreamKey}>get stream key</button>
                <button className="red-teder-btn" onClick={handleFinishShow}>Finish Show</button>
            </div>
            <div className="container set-up-stream-container">

                <div className="row teder-top-margin">
                    <label htmlFor="streamURL" className="teder-form-label">Your Stream Show Url:</label>
                    <input id="streamURL" className="form-control artist-textbox-style" type="text" value={Config().BaseUrl+"/#/shows/" + currentShow} readOnly></input> <br />
                </div>

                <br /><br />
                {obsInstructions()}

                <Modal
                    isOpen={getStreamKey}
                    ariaHideApp={false}
                    className="Modal"
                    overlayClassName="Overlay"
                    contentLabel="Purchase Modal"
                >
                    <div className="teder-modal-container">
                        <div className="modal-body">
                            <div className="teder-modal-title text-center">Your stream key</div>
                            <div className="teder-modal-body text-center">
                                <br />
                                <p>Copy and paste the below to your OBS.</p>
                                <input className="form-control strem-key" type="text" value={currentState.viewShowDetails.showStreamKey} readOnly></input>
                                {!currentState.viewShowDetails.showStreamKey && <small className="static-link">{readyToStreamError}</small>}
                            </div>
                            <br />
                            <div className="text-center">
                                <button type="button" className="green-teder-btn" onClick={() => setGetStreamKey(false)}>close</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={finishShow}
                    ariaHideApp={false}
                    className="Modal"
                    overlayClassName="Overlay"
                    contentLabel="Purchase Modal"
                >
                    <div className="teder-modal-container">
                        <div className="modal-body">
                            <div className="teder-modal-title text-center">Careful!</div>
                            <div className="teder-modal-body text-center">
                                <p>Once you finish your show you will not be able to stream anymore.</p>
                            </div>
                            <br />
                            <div className="teder-modal-subtitle text-center">finish your show?</div><br />
                            <div className="text-center">
                                <button type="button" className="red-teder-btn" onClick={finishMyShow}>Yes</button>
                                <button type="button" className="green-teder-btn" onClick={() => setFinishShow(false)}>No</button>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        </>
    )

    const redirectUser = () => {
        dispatch(push('/'))
        return "Not your show"
    }

    return currentState.userShows[0] ? prepStream() : redirectUser()
}

export default StreamShowPage