import { useTheme } from "@mui/material";

export const useStyles = () => {
  const theme = useTheme();
  const mediaQuery = theme.breakpoints.down("sm");

  const styles = {
    wrapper: {
      width: "424px",
      display: "flex",
      flexDirection: "column",
      background: theme.palette.tederColors.v2DarkBlue,
      // border: "solid .5px white",
      borderRadius: "16px",
      position: "relative",
      [mediaQuery]: {
        width: "80vw",
        height: "80vh",
      },
    },
    infoBox: {
      borderRadius: "15px",
      marginTop: "6%",
      minHeight: "201px",
      padding: "5px 45px",
      background: "#0A0722",
    },
    infoText: {
      fontFamily: "onestthin",
      color: "white",
      whiteSpace: "nowrap",
    },

    infoLink: {
      position: "absolute",
      top: "30%",
      left: "20%",
      border: "solid 2px white",
      width: "60%",
      color: "white",
      borderRadius: "15px",
    },

    image: {
      // boxShadow: "5px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "15px 15px 0 0",
      maxHeight: "410px",
      maxWidth: "auto",
    },
    arrow: {
      position: "absolute",
      top: "30px",
      right: "30px",
      width: "60px",
      height: "60px",
      cursor: "pointer",
    },

    price: {
      marginRight: "10px",
    },

    priceText: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "2em",
      [mediaQuery]: {
        marginTop: "-1em",
        paddingLeft: ".5em",
      },
    },

    artistText: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },

    artistPhoto: {
      border: "solid 1px white",
      borderRadius: "50%",
      maxHeight: "65px",
      maxWidth: "65px",
      margin: "15px 10px 10px 20px",
      // [mediaQuery]: {
      //   top: "200px",
      // },
    },
    artistName: {
      position: "absolute",
      top: "40px",
      right: "50px",
      texTransform: "none",
      border: "solid 1px red",
      // [mediaQuery]: {
      //   left: "35%",
      //   top: "5px",
      // },
    },
    title: {
      color: theme.palette.tederColors.v2Purple,
      position: "absolute",
      top: "50px",
      right: "260px",
      fontSize: ".95em",
      [mediaQuery]: {
        left: "40%",
        top: "45%",
        bottom: "5px",
      },
    },
    button: {
      position: "absolute",
      fontFamily: "onestmedium",
      bottom: "25px",
      right: "35px",
      [mediaQuery]: {
        bottom: "25px",
      },
    },
  };
  return styles;
};
