import React, { useState } from "react";
import "./viewShowPage.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { get_stream_info } from "../../../Common/data";
import { GET_IP_API } from "../../../util/constants";

const ViewShowPage = () => {
    const playerHeight = 600;
    const { id: showId } = useParams();
    const currentState = useSelector((state) => state.TederReducerState);
    const [ticket, setTicket] = useState();
    const [videoUrl, setVideoUrl] = useState();
    const [showPlayer, setShowPlayer] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const handleJoin = async () => {
        try {
            //const ipResult = await fetch(GET_IP_API);
            //const { IPv4 } = await ipResult.json();
            const IPv4 = "0.0.0.0";
            const get_stream_params = { ticket_number: ticket, show_id: showId, ip_address: IPv4 };

            const res = await get_stream_info(get_stream_params);
            const result = JSON.parse(res.body);

            if (result.code === "Success") {
                setVideoUrl(result.response.liveapi_embed_url);
                setShowPlayer(true);
                setErrorMessage("");
            } else {
                setErrorMessage(result.message || "An error occurred!");
            }
        } catch (error) {
            console.error("Error during stream setup", error);
            setErrorMessage("Failed to load stream information.");
        }
    };

    const getVideoIframe = () => ({
        __html: `<iframe title="Live Stream" src="${videoUrl}" width="100%" height="${playerHeight}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
    });

    const getPlayer = () => showPlayer ? (
        <>
            <div className="row">
                <h3>Now Showing: {currentState.viewShowDetails.showName}</h3>
            </div>
            <div dangerouslySetInnerHTML={getVideoIframe()} />
        </>
    ) : null;

    return (
        <div style={styles.container}>
            <h1>Join the concert</h1>
            <p>Enter your ticket number below:</p>
            <input
                className="watch-show-input"
                onBlur={(e) => setTicket(e.target.value.trim())}
                id="ticketNumber"
                type="text"
                placeholder="Ticket number"
            />
            <button onClick={handleJoin} className="watch-show-btn">Enter</button>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {getPlayer()}
        </div>
    );
};

const styles = {
    container: {
        margin: "0 auto",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flexGap: "3%",
        height: "100vh",
    },
};

export default ViewShowPage;
