import {
  admin_run_vimeo_prep,
  admin_update_video_ready_to_stream,
  admin_get_video_status,
  admin_end_video,
  admin_get_video_stream_details,
  admin_reset_show_status,
  admin_get_temp_recording,
  admin_get_stream_stats,
  end_streaming,
  admin_convert_to_vod,
} from "../../Common/data";
// import { useSelector } from "react-redux";
import React, { useState } from "react";
import { Modal, Typography, Box, List, ListItem, ListItemText } from "@mui/material";

const ModalContent = ({ data }) => {
  return  (
    <List>
      {Object.entries(data).map(([key, value]) => {
        const title = key.split('_').join(' ')
        return (
          <ListItem key={key} style={{ display: 'flex', borderBottom: '0.5px solid #95959d' }}>
            <ListItemText primary={title} style={{ maxWidth: '35%', textTransform: 'capitalize' }}/>
            <ListItemText primary={value} style={{ maxWidth: '65%' }}/>
          </ListItem>
        )
      })}
    </List>
  )
}


const AdminGeneral = () => {
  // const currentState = useSelector((state) => state.TederReducerState)
  const [showID0, setShowID0] = useState();
  const [showID1, setshowID1] = useState();
  const [showID2, setshowID2] = useState();
  const [showID3, setshowID3] = useState();
  const [showID4, setshowID4] = useState();
  const [showID5, setshowID5] = useState();

  const [message, setMessage] = useState();
  const [VideoReadyResult, setVideoReadyResult] = useState();
  const [VideoStatusResult, setVideoStatusResult] = useState();
  const [EndVideoResult, seEndVideoResult] = useState();
  const [ShowStreamInfo, seShowStreamInfo] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null)
  const [convertToVODInput, setConvertToVODInput] = useState({})

  const handleInputChange = (e) => {
    setConvertToVODInput({
      ...convertToVODInput,
      [e.target.id]: e.target.value
    })
  }

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleResetShowStatus = () => {
    const showId = {
      event_id: parseInt(showID0),
    };
    admin_reset_show_status(showId)
      .then((res) => console.log("reset show status", res))
      .catch((error) => console.error("reset show status error", error));
  };

  const handleEventPrep = () => {
    let data = {};

    admin_run_vimeo_prep(data)
      .then((result) => {
        setMessage(result.message);
      })
      .catch((error) => {
        console.log("Error caught in admin_run_vimeo_prep api");
        console.log(error);
        return false;
      });
  };

  const handleShowReady = () => {
    let data = {
      video_url: "/videos/" + showID1,
    };

    admin_update_video_ready_to_stream(data)
      .then((result) => {
        setVideoReadyResult(result.vimeo_response[0]);
      })
      .catch((error) => {
        console.log("Error caught in admin_run_vimeo_prep api");
        console.log(error);
        setVideoReadyResult(error);
        return false;
      });
  };

  const handleCheckShowStatus = () => {
    let data = {
      video_url: "/videos/" + showID2,
    };

    admin_get_video_status(data)
      .then((result) => {
        setVideoStatusResult(result.vimeo_response);
      })
      .catch((error) => {
        console.log("Error caught in admin_run_vimeo_prep api");
        console.log(error);
        setVideoStatusResult(error);
        return false;
      });
  };

  const handleEndShow = () => {
    const params = [{
      key: 'ShowID',
      value: showID3,
    }];
    end_streaming(params).then((result) => {
        if (result.code === "Success") {
            alert("Show Ended")
        }
        else {
            console.error(result)
            alert("Something went wrong")
        }

    }).catch((error) => {
        console.error(error)
        alert(error)
    })
  };

  const handleGetShowStreamDetails = () => {
    let data = {
      show_id: showID4,
    };

    admin_get_video_stream_details(data)
      .then((result) => {
        seShowStreamInfo(result.stream_info);
      })
      .catch((error) => {
        console.log("Error caught in admin_end_video api");
        console.log(error);
        seShowStreamInfo(error);
        return false;
      });
  };

  const getTempRecordings = () => {
    const payload = [{
      key: 'stream_id',
      value: showID5,
    }];
   
    admin_get_temp_recording(payload)
      .then((res) => {
        let { body } = res
        body = JSON.parse(body)
        if (body?.code == 'Success') {
          const data = body?.response[0]
          let payload = {}
          Object.keys(data).map(key => {
            if (typeof(data[key]) != 'object') payload[key] = data[key]
          })
          handleModalOpen()
          setModalData({ title: 'Recording Data', data: payload })
        }
      })
      .catch((error) => console.error("temp recording errors", error));
  }

  const getShowStatistcs = () => {
    const payload = [{
      key: 'stream_id',
      value: showID0,
    }];

    admin_get_stream_stats(payload)
      .then((res) => {
        let { body } = res
        body = JSON.parse(body)
        if (body?.code == 'Success') {
          const data = body?.response[0]
          let payload = {}
          Object.keys(data).map(key => {
            if (typeof(data[key]) != 'object') payload[key] = data[key]
          })
          handleModalOpen()
          setModalData({ title: 'Stream Statistics', data: payload })
        }
      })
      .catch((error) => console.error("show statistics", error));
  }

  const handleConvertToVOD = async () => {
    let payload = [{
      key: 'stream_id',
      value: convertToVODInput.stream_id,
    }, {
      key: 'recording_id',
      value: convertToVODInput.recording_id,
    }];

    admin_get_temp_recording([{
      key: 'stream_id',
      value: convertToVODInput.stream_id
    }]).then((response) => {
      let { body } = response
      body = JSON.parse(body)
      if (body?.code == 'Success') {
        const data = body?.response[0]
        let recordingData = {}
        Object.keys(data).map(key => {
          if (typeof(data[key]) != 'object') recordingData[key] = data[key]
        })
        if (recordingData.start_time) {
          payload = [
            ...payload,
            {
              key: 'from',
              value: recordingData.start_time,
            },
            {
              key: 'duration',
              value: recordingData.duration,
            }
          ]
        }
        admin_convert_to_vod(payload)
        .then(res => {
          let { body } = res
          body = JSON.parse(body)
          if (body?.code == 'Success') {
            const data = body?.response[0]
            let payload = {}
            Object.keys(data).map(key => {
              if (typeof(data[key]) != 'object') payload[key] = data[key]
            })
            handleModalOpen()
            setModalData({ title: 'VOD Conversion', data: payload })
          }
        })
        .catch(err => {
          console.error("convert to VOD error ", err)
        })
      }
    }).catch(err => {
      console.error("get temp recording error ", err)
    })
  }

  return (
    <div className="row adminRow">
      <h3>General Admin Actions</h3>
      <div className="col-md-12">
        <table className="table tableWhite">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Action</th>
              <th scope="col">Result</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>Get Recordings</td>
              <td>
                <input
                  type="text"
                  id="showID0"
                  onChange={(e) => setshowID5(e.target.value)}
                  placeholder="Enter Stream ID Here"
                ></input>
                <button onClick={getTempRecordings} className="btn-primary">
                  Get
                </button>
              </td>
              <td colSpan="2">
                <small>{message}</small>
              </td>
            </tr>
            <tr>
              <td>Run Vimeo Event Prep</td>
              <td>
                <button onClick={handleEventPrep} className="btn-primary">
                  Execute
                </button>
              </td>
              <td colSpan="2">
                <small>{message}</small>
              </td>
            </tr>
            <tr>
              <td>Get Show Statistics</td>
              <td>
                <input
                  type="text"
                  id="showID0"
                  onChange={(e) => setShowID0(e.target.value)}
                  placeholder="Enter Stream ID Here"
                ></input>
                <button onClick={getShowStatistcs} className="btn-primary">
                  Get
                </button>
              </td>
              <td colSpan="2">
                <small>{message}</small>
              </td>
            </tr>
            <tr>
              <td>Reset Event Status</td>
              <td>
                <input
                  type="text"
                  id="showID0"
                  onChange={(e) => setShowID0(e.target.value)}
                  placeholder="Enter Show ID Here"
                ></input>
                <button onClick={handleResetShowStatus} className="btn-primary">
                  Reset
                </button>
              </td>
              <td colSpan="2">
                <small>{message}</small>
              </td>
            </tr>
            <tr>
              <td>Update video - Ready to stream</td>
              <td>
                <input
                  type="text"
                  id="showID1"
                  onChange={(e) => setshowID1(e.target.value)}
                  placeholder="Enter Show ID Here"
                ></input>
                <button onClick={handleShowReady} className="btn-primary">
                  Update
                </button>
              </td>
              <td>
                <small>{VideoReadyResult}</small>
              </td>
            </tr>
            <tr>
              <td>Check video state</td>
              <td>
                <input
                  type="text"
                  id="showID2"
                  onChange={(e) => setshowID2(e.target.value)}
                  placeholder="Enter Show ID Here"
                ></input>
                <button onClick={handleCheckShowStatus} className="btn-primary">
                  Check
                </button>
              </td>
              <td>
                <small>{VideoStatusResult}</small>
              </td>
            </tr>
            <tr>
              <td>End video</td>
              <td>
                <input
                  type="text"
                  id="showID3"
                  onChange={(e) => setshowID3(e.target.value)}
                  placeholder="Enter Show ID Here"
                ></input>
                <button onClick={handleEndShow} className="btn-primary">
                  End Video Stream
                </button>
              </td>
              <td>
                <small>{EndVideoResult}</small>
              </td>
            </tr>
            <tr>
              <td>Get Teder Show Stream Info</td>
              <td>
                <input
                  type="text"
                  id="showID4"
                  onChange={(e) => setshowID4(e.target.value)}
                  placeholder="Enter Show ID Here"
                ></input>
                <button
                  onClick={handleGetShowStreamDetails}
                  className="btn-primary"
                >
                  Get Show Stream Info
                </button>
              </td>
              <td>
                <small>{ShowStreamInfo}</small>
              </td>
            </tr>
            <tr>
              <td>Convert Recording to VOD</td>
              <td>
                <input
                  type="text"
                  id="stream_id"
                  onChange={handleInputChange}
                  placeholder="Enter Stream ID Here"
                ></input>
                <input
                  type="text"
                  id="recording_id"
                  onChange={handleInputChange}
                  placeholder="Enter Recording ID Here"
                ></input>
                <button
                  onClick={handleConvertToVOD}
                  className="btn-primary"
                >
                  Convert To VOD
                </button>
              </td>
              <td>
                <small>{ShowStreamInfo}</small>
              </td>
            </tr>
          </tbody>
        </table>

        <Modal open={modalOpen} onClose={handleModalClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              backgroundColor: '#1b194d',
              minWidth: '500px',
              p: 2,
            }}
          >
            <Typography variant="h6">{modalData?.title}</Typography>
            <ModalContent data={modalData?.data}/>
          </Box>
        </Modal>
    </div>
 </div>
  );
};

export default AdminGeneral;
