import styles from "./styles.module.css";

export default CaseStudiesCard;

function CaseStudiesCard(props) {
  const { image, name, title, quotation_mark, testimony } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <img src={image} alt="categories" className={styles.picture} />
        <div className={styles.column}>
          <p className={styles.name}>{name}</p>
          <p className={styles.title}>{title}</p>
        </div>
        <img src={quotation_mark} className={styles.quote} alt="quote" />
      </div>
      <p className={styles.testimony}>{testimony}</p>
    </div>
  );
}
