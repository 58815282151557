import React, { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { useDispatch } from "react-redux";

//Components
import Headline from "../Headline";
import HomePageSection from "../HomePageSection";

export default LandingV2;

function LandingV2() {
  const dispatch = useDispatch();
  useEffect(() => dispatch({ type: "CLEAR_SHOW_DETAILS" }), []);

  return (
    <>
      <Headline />
      <HomePageSection />
    </>
  );
}
