import styles from "./styles.module.css";
import RevenueDashboard from "../img/revenue_dashboard.svg";
import ColorFulParagraph from "../img/colorful_paragraph.svg";

export default HomePageSection;

function HomePageSection() {
  return (
    <section className={styles.homepage_section}>
      <div className={styles.wrapper}>
        <div className={styles.title_wrapper}>
          <p className={styles.title}>Discover your brand's potential.</p>
          <p className={styles.paragraph}>
            From ticket sales, giveaways, merch sales, exclusive releases, to
            getting connected with the people who love what you do, there's so
            many great perks of taking the next step with us.
          </p>
          <img
            className={styles.colorful_paragraph}
            src={ColorFulParagraph}
            alt="Create your on demand events,
            sell your tickets, perform live seamlessly,
            interact with your fans, get insightful analytics,
            earn recurring revenue"
          />
        </div>
        <div>
          <img
            className={styles.laptop}
            src={RevenueDashboard}
            alt="revenue dashboard"
          />
        </div>
      </div>
    </section>
  );
}
