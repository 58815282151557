import styles from "./styles.module.css";
export default Button;

function Button(props) {
  return (
    <button
      {...props}
      className={
        props.main
          ? styles["teder-button-main"]
          : props.secondary
          ? styles["teder-button-secondary"]
          : styles["teder-button"]
      }
    >
      {props.children}
    </button>
  );
}
